<template>
  <div class="eagleeyeList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="false"
    >
      <template #headerSlot>
        <v-button text="新建鹰眼组" permission="add" @click="create"></v-button>
        <v-button text="配置物管鹰眼" permission="wgadd" @click="createWgadd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="鹰眼组名称" v-model="searchParams.groupName"></v-input>
        <v-select label="使用者类型" v-model="searchParams.groupType" :options="groupTypeOps"></v-select>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-input label="创建人" v-model="searchParams.userName"></v-input>
        <v-datepicker label="录入时间段" :maxDate="maxDate" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, batchURL } from './api'
import { searchGroupTypeOps, listStatusOps } from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'EagleeyeList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      groupTypeOps: searchGroupTypeOps,
      statusOps: listStatusOps,
      maxDate: new Date(),
      communityParams,
      searchParams: {
        groupName: '',
        groupType: undefined,
        status: undefined,
        communityId: '',
        userName: '',
        startDate: '',
        endDate: ''
      },
      headers: [
        {
          prop: 'groupName',
          label: '鹰眼组名称'
        },
        {
          prop: 'groupType',
          label: '使用者类型'
        },
        {
          prop: 'userCount',
          label: '关联人数',
          align: 'right'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'status',
          label: '状态'
        },
        {
          prop: 'userName',
          label: '创建人'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    updateStatus (ids, batchURL) {
      let _this = this
      let _ids = ids.join(',')
      let postData = {
        ids: _ids,
        dataObject: _ids
      }
      _this.$axios.post(batchURL, postData).then(res => {
        if (res.status === '100') {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    batch (selected) {
      this.updateStatus(selected.ids, batchURL)
    },
    create () {
      this.$router.push({
        name: 'eagleeyeForm'
      })
    },
    createWgadd () {
      this.$router.push({
        name: 'wgEagleeyeForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'eagleeyeForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
