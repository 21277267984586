
// 状态
const statusMap = {
  0: '离线',
  1: '在线'
}
// 使用者类型
const searchGroupTypeOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '用户',
    value: 1
  }, {
    text: '物管',
    value: 2
  }
]
// 状态
const listStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '启用',
    value: 1
  },
  {
    text: '关闭',
    value: 0
  }
]
const groupTypeOps = [
  {
    text: '用户',
    value: 1
  },
  {
    text: '物管',
    value: 2
  }
]
const statusOps = [
  {
    text: '启用',
    value: 1
  },
  {
    text: '关闭',
    value: 0
  }
]
const searchStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '在线',
    value: 1
  },
  {
    text: '离线',
    value: 0
  }
]

export {
  statusMap,
  searchGroupTypeOps,
  listStatusOps,
  groupTypeOps,
  statusOps,
  searchStatusOps
}
